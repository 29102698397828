import "object.values";
import "core-js/stable";

import React from "react";
import { render } from "react-dom";

// Provider
import { Provider } from "react-redux";

// Styles
import "./styles/index.scss";

// Store
import { store } from "./core/store";

// Components
import PageThankYou from './views/components/pages/page-thank-you';

const rootElement = document.getElementById("root");

const renderApp = () =>
    render(
        <Provider store={store}>
            <PageThankYou />
        </Provider>,
        rootElement
    );

renderApp();
