import React, { useState, Fragment } from "react";

const BackgroundImage = ({ src }) => {
    const [loaded, toggleLoading] = useState(false);

    return (
        <Fragment>
            <img
                src={src}
                onLoad={() => toggleLoading(true)}
                alt="Illustaration"
                className="display-none"
            ></img>

            <div
                style={loaded ? { backgroundImage: `url(${src})` } : null}
                className={`overlay img-background img-animated loading ${
                    loaded && "loaded"
                }`}
            ></div>
        </Fragment>
    );
};

export default BackgroundImage;
