import React from "react";

import BackgroundImage from "../images/background";

const Container = ({ children, className = "" }) => {
    return (
        <div className={`box flex flex-center flex-align ${className}`}>
            <BackgroundImage
                inview={true}
                src={`${process.env.PUBLIC_URL}/backgrounds/BG-1.png`}
            />
            <div className="container container-m center relative">
                {children}
            </div>
        </div>
    );
};

export default Container;
