import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    authenticated: false,
    loaded: false,
    sessionNotFound: false,
    session: null,
};

const slice = createSlice({
    name: "session",
    initialState,
    reducers: {
        setAuthentication(state) {
            state.authenticated = true;
        },
        setSession(state, { payload }) {
            state.loaded = true;
            state.session = payload;
        },
        setSessionNotFound(state) {
            state.sessionNotFound = true;
        },
        reset: (_) => initialState,
    },
});

export const {
    setAuthentication,
    setSession,
    setSessionNotFound,
    reset,
} = slice.actions;

export default slice.reducer;
