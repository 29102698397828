import React from "react";

// Components
import Container from "../container";
import Image from "../images/image";

const PageThankYou = () => {
    return (
        <Container>
            <Image
                width="190px"
                height="200px"
                src={`${process.env.PUBLIC_URL}/logos/Logo - Baas boven Baas_1@4x.png`}
            />
            <h2 className="mt-4 text-2xl">
                Bedankt voor het bezoeken, check snel terug voor de eindvideo.
            </h2>
        </Container>
    );
};

export default PageThankYou;
