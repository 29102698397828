import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    open: false,
    message: null,
    error: null,
};

const slice = createSlice({
    name: "notification",
    initialState,
    reducers: {
        setNotification(state, { payload }) {
            state.open = true;
            state.message = payload.message;
            state.error = payload.error || null;
        },

        reset(state) {
            state.open = false;
        },
    },
});

export const { setNotification, reset } = slice.actions;

export default slice.reducer;
