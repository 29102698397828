import { combineReducers } from "@reduxjs/toolkit";

import sessionReducer from "./session/slice";
import notificationReducer from "./notifications/slice";
import questionsReducer from "./questions/slice";

export const reducers = combineReducers({
    session: sessionReducer,
    notification: notificationReducer,
    questions: questionsReducer,
});
