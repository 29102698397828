import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    list: null,
};

const slice = createSlice({
    name: "questions",
    initialState,
    reducers: {
        setQuestions(state, { payload }) {
            state.list = payload;
        },

        reset: (_) => initialState,
    },
});

export const { setQuestions, reset } = slice.actions;
export default slice.reducer;
